import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { ErrorToast } from '../../helpers/toasters'
import ManageUser from './components/ManageUser'

export default function UserInfo() {

    const {id} = useParams();
    // const id = 'd48a0ac8-0aac-ce9f-2f43-b0823a8458d1'
    const [repInfo,setRepInfo] = useState([])
    const [orders,setOrders] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('profile')
    const [submitting,setSubmitting] = useState(false)
    const [showReset,setShowReset] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit User</button>
          {!repInfo?.tempPass ? <button type='button' className='btn-md ml-4' onClick={()=>onReset()}>Reset Password</button> : null}
        </div>
    }

    async function getUserInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user/${id}?populate=true`)
            setRepInfo(res.data)
        } catch (error) {
            ErrorToast('Error getting users')
        }
    }
    useEffect(()=>
    {
        getUserInfo();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/user/${id}?populate=true`,data) 
            await getUserInfo()
            setShowEdit(false)
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            ErrorToast(error.response.data)
        }
    }

    const onReset = async()=>
    {
        try 
        {
            // e.preventDefault()
            setSubmitting(true)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/user/generate-password/${id}`) 
            await getUserInfo()
            // setShowReset(false)
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            ErrorToast(error.response.data ? error.response.data : 'Error resetting password')
        }
    }

  return (
    <>
        <Header title={repInfo.name} RightContent={RightContent} parentTitle='User Info' parentLink='users'/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            {/* <div>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Orders',value:'orders'},{label:'Route Schedule',value:'route-schedule'},{label:'Profile',value:'profile'}]}/>
            </div> */}
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <ProfileInfo id={id} info={repInfo}/>
            
        </div>
        { showEdit ? <ManageUser submitting={submitting} onClose={setShowEdit} onSubmit={onSubmit} edit={id} updateData={repInfo}/> : null}
    </>
  )
}




const ProfileInfo = ({info})=>
{

    return <div className='flex justify-start items-start h-[100%] w-full self-stretch py-12 px-6'>
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Name</label>
                <p className='text-sm font-medium'>{info?.name}</p>
            </div>
            <div>
                <label>Email</label>
                <p className='text-sm font-medium'>{info?.email}</p>
            </div>
            <div>
                <label>Temporary Password</label>
                <p className='text-sm font-medium'>{info?.tempPass ? info?.tempPass : '-'}</p>
            </div>
            <div>
                <label>Status</label>
                <p className='text-sm font-medium'>{info?.active ? 'Active' : 'Inactive'}</p>
            </div>
        </div>
    </div>
}
