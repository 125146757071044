import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../images/logo.png'
import DashboardIcon from '../images/dashboard.svg'
import OrdersIcon from '../images/orders.svg'
import SalesRepIcon from '../images/sales-rep.svg'
import SettingsIcon from '../images/settings.svg'
import TeaIcon from '../images/tea.svg'
import FarmIcon from '../images/farm.svg'
import WarehouseIcon from '../images/warehouse.svg'
import UpIcon from '../images/up.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout, updateUserRole } from '../redux/slice/authSlice'
import { BiCart, BiLeaf, BiLogOut, BiSolidDashboard } from 'react-icons/bi'
import { PiCirclesFour, PiFactory, PiFarm, PiTruckTrailer, PiUsers, PiUsersFour, PiWarehouse } from 'react-icons/pi'
import { LuSettings2 } from 'react-icons/lu'
import { TbUsers } from 'react-icons/tb'
import { BsBoxes, BsBuilding, BsTruck } from 'react-icons/bs'
import { FaBoxesStacked, FaClipboardList, FaWarehouse } from 'react-icons/fa6'
import { FiLogOut } from 'react-icons/fi'
import { MdUpdate, MdViewList, MdWaterDrop } from 'react-icons/md'
import { CiViewList } from 'react-icons/ci'
import { HiOutlineClipboardList } from 'react-icons/hi'
import { authAxios } from '../helpers/axios'
import { ErrorToast } from '../helpers/toasters'
import { IoWaterOutline } from 'react-icons/io5'


const SidebarMenuSubItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`menu-sub-item relative my-3 mb-2 ${collapsed ? 'pl-2' : 'pl-4'}`}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
    isActive ? " inline-block menu-sub-active group active" : "inline-block"
  }>
            <div className={`w-auto mx-auto flex rounded-md items-center group-[.menu-sub-active]:bg-[#313B53] transition-all duration-[300ms] ${collapsed ? 'ml-4 px-4' : 'ml-10 px-4'}`}>
            <div className='py-3'>
            </div>
            <div className={`${!collapsed ? '' :'ml-0'}`}>
                <p className={`text-[13px] whitespace-nowrap font-normal transition-all duration-[300ms] text-[#151515] group-[.active]:text-white ${!collapsed ? 'w-auto opacity-100' :'w-0 overflow-hidden opacity-0'}`}>{data.label}</p>
            </div>
        </div>
        </NavLink>
        </div>
}

const SidebarMenuItem = ({active,setActive,data,collapsed,Icon})=>
{
    return  <div className={`menu-item transition-all relative  ${active === data.id && data.childrens && data.childrens.length >0 ? 'menu-item-active border-b-stone-700 border-b-[0]' : ''}`} onClick={()=>setActive(data.id)}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
        {
            if(isActive) setActive(data.id);
            return isActive ? " block group my-2 parent-active opacity-100 bg-[#e6f3f1] rounded-md shadow-none border border-gray-100" : " filter grayscale block border border-transparent my-2"
        }
}>
            <div className={`w-full flex px-4 items-center `}>
      {/* {active === data.id ? <div className='h-full w-[6px] bg-[#2ac08b] absolute top-0 left-0 rounded-r-full'></div> : null} */}
                <div className='w-full flex items-center'>
                    <div className='py-2'>
                        {/* <img src={data.icon} className='w-5 h-5 '/> */}
                        {data.id === 1 ? <PiCirclesFour color={active === 1 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 2 ? <HiOutlineClipboardList color={active === 2 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 3 ? <BiCart color={active === 3 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 4 ? <PiFactory color={active === 4 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 5 ? <BsBuilding color={active === 5 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 6 ? <PiTruckTrailer color={active === 6 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 7 ? <BsBoxes color={active === 7 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 12 ? <BiLeaf color={active === 7 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 13 ? <IoWaterOutline color={active === 7 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 14 ? <MdUpdate color={active === 7 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 11 ? <LuSettings2 color={active === 7 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 10 ? <PiFarm color={active === 7 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 8 ? <PiUsersFour color={active === 8 ? '#225442' : '#000'} fontSize={20}/> : null}
                        {data.id === 9 ? <PiWarehouse color={active === 9 ? '#225442' : '#000'} fontSize={20}/> : null}
                    </div>
                    {!collapsed ? <div className='ml-4'>
                        <p className={`text-[13px] tracking-[-.1px] font-medium  ${active === data.id ? 'text-[#225442]' : 'text-[#151515]'}`}>{data.label}</p>
                        {/* <p className='text-[13px] tracking-[-.15px] font-medium text-[#757575] hover:text-[#2ac08b] group-[.parent-active]:text-[#2ac08b]'>{data.label}</p> */}
                    </div> : null}
                </div>
                {!collapsed && data.childrens && data.childrens.length > 0 ? <div className=''>
                    <img src={UpIcon} className={`w-3 h-3 ${active !== data.id ? 'origin-center rotate-180' : ''}`}/>
                </div> : null}
        </div>
        </NavLink>
        {
            data.childrens && data.childrens.length > 0 ? <div className={`overflow-hidden transition-all ${active !== data.id ? 'h-0 ' : 'h-full py-4'}`}>
            {
                data.childrens.map((item,index)=>
                {
                    return <SidebarMenuSubItem data={item} key={index} active={active} collapsed={collapsed}/>
                })
            }
        </div> : null
        }
        </div>
}
export default function Sidebar() {

    const [activeMenu,setActiveMenu] = useState(1)
    const [collapsed,setCollapsed] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authInfo = useSelector(state=>state.auth)

    const {pathname} = useLocation();


    async function precheckUser(){

        try 
        {
            let userInfo = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user/precheck`)
            dispatch(updateUserRole({role:userInfo.data.role}))
            // setLoading(false)
            } catch (error) {
            // setLoading(false)
            ErrorToast('Error getting User Info')
        }
    }

    useEffect(()=>{
        if(!authInfo.isLoggedIn || !authInfo.token) return navigate('/login')
        // else 
        // precheckUser()
    },[authInfo])
    
    
    // useEffect(()=>{
    //     if(!authInfo.isLoggedIn || !authInfo.token) return navigate('/login')
    // },[authInfo])

    useEffect(() => {
        if (!authInfo.role) return;

        const allowedRoutes = {
            production: ['/manufacturing', '/shipping', '/','/product-stocks'],
            sales: ['/','/sales','/buyers'],
            purchase: ['/purchase', '/','/stocks','/common-bom','/purchase-stocks','/suppliers'],
            tea: ['/sales','/raw-tea', '/blended-tea','/warehouse','/gardens','/buyers','/settings','/updates','/users']
        };

        // Admin can access all routes, no need to check
        if (authInfo.role === 'admin') return;

        const currentAllowedRoutes = allowedRoutes[authInfo.role] || [];
        
        // Check if current path starts with any allowed route
        const isAllowed = currentAllowedRoutes.some(route => 
            pathname === route || pathname.startsWith(`${route}/`)
        );

        if (!isAllowed) {
            // Redirect to first allowed route for their role
            navigate(currentAllowedRoutes[0]);
        }
    }, [pathname, authInfo.role]);

    const onLogout = ()=>
    {
        dispatch(logout())
    }

    const roleBasedMenuItems = {
        sales:[
            {
                id: 1,
                label: 'Dashboard',
                icon: DashboardIcon,
                childrens: null,
                url: '/'
            },
            {
                id: 2,
                label: 'Sales',
                icon: OrdersIcon,
                childrens: null,
                url: '/sales'
            },
            {
                id: 5,
                label: 'Buyers',
                icon: SettingsIcon,
                url: "/buyers",
            },
        ],
        tea:[
            {
                id: 2,
                label: 'Sales',
                icon: OrdersIcon,
                childrens: null,
                url: '/sales'
            },
            {
                id: 12,
                label: 'Raw Tea',
                icon: TeaIcon,
                url: '/raw-tea',
            },
            {
                id: 13,
                label: 'Blended Tea',
                icon: TeaIcon,
                url: '/blended-tea',
            },
            {
                id: 9,
                label: 'Warehouse',
                icon: WarehouseIcon,
                url: '/warehouse',
            },
            {
                id: 10,
                label: 'Gardens',
                icon: FarmIcon,
                url: "/gardens",
            },
            {
                id: 11,
                label: 'Settings',
                icon: SettingsIcon,
                url: '/settings',
            },
        ],
        production: [
            {
                id: 1,
                label: 'Dashboard',
                icon: DashboardIcon,
                childrens: null,
                url: '/'
            },
            {
                id: 4,
                label: 'Manufacturing',
                icon: SalesRepIcon,
                url: '/manufacturing',
            },
            {
                id: 6,
                label: 'Shipping',
                icon: SettingsIcon,
                url: "/shipping",
            },
            {
                id: 9,
                label: 'Finished Product Stocks',
                icon: SettingsIcon,
                url: "/product-stocks",
            },
        ],
        purchase: [
            {
                id: 1,
                label: 'Dashboard',
                icon: DashboardIcon,
                childrens: null,
                url: '/'
            },
            {
                id: 3,
                label: 'Purchase',
                icon: SalesRepIcon,
                url: '/purchase',
            },
            {
                id: 8,
                label: 'Suppliers',
                icon: SettingsIcon,
                url: "/suppliers",
            },
            {
                id: 7,
                label: 'Stocks',
                icon: SettingsIcon,
                url: "/purchase-stocks",
            },
            {
                id: 12,
                label: 'Common BOM',
                icon: SettingsIcon,
                url: "/common-bom",
            },
        ],
        admin: [
            {
                id: 1,
                label: 'Dashboard',
                icon: DashboardIcon,
                childrens: null,
                url: '/'
            },
            {
                id: 2,
                label: 'Sales',
                icon: OrdersIcon,
                childrens: null,
                url: '/sales'
            },
            {
                id: 3,
                label: 'Purchase',
                icon: SalesRepIcon,
                url: '/purchase',
            },
            {
                id: 4,
                label: 'Manufacturing',
                icon: SalesRepIcon,
                url: '/manufacturing',
            },
            {
                id: 6,
                label: 'Shipping',
                icon: SettingsIcon,
                url: "/shipping",
            },
            {
                id: 5,
                label: 'Buyers',
                icon: SettingsIcon,
                url: "/buyers",
            },
            {
                id: 8,
                label: 'Suppliers',
                icon: SettingsIcon,
                url: "/suppliers",
            },
            {
                id: 10,
                label: 'Gardens',
                icon: FarmIcon,
                url: "/gardens",
            },
            {
                id: 11,
                label: 'Warehouse',
                icon: WarehouseIcon,
                url: "/warehouse",
            },
            {
                id: 12,
                label: 'Raw Tea',
                icon: TeaIcon,
                url: "/raw-tea",
            },
            {
                id: 13,
                label: 'Blended Tea',
                icon: TeaIcon,
                url: "/blended-tea",
            },
            {
                id: 8,
                label: 'Stocks',
                icon: SettingsIcon,
                url: "/purchase-stocks",
            },
            {
                id: 12,
                label: 'Common BOM',
                icon: SettingsIcon,
                url: "/common-bom",
            },
            {
                id: 14,
                label: 'Updates',
                icon: SettingsIcon,
                url: "/updates",
            },
            {
                id: 9,
                label: 'Finished Product Stocks',
                icon: SettingsIcon,
                url: "/product-stocks",
            },
            {
                id: 10,
                label: 'Users',
                icon: SettingsIcon,
                url: '/users'
            },
            {
                id: 11,
                label: 'Settings',
                icon: SettingsIcon,
                url: '/settings'
            }
        ]
    };

    const data = roleBasedMenuItems[authInfo.role] || [];
  return (
    <div className='w-auto h-screen flex transition-all'>
        <div className={`hidden md:block bg-[#FBFCFE] border-r border-r-[#efefef] shrink-0 max-w-[280px] transition-all duration-[300ms] w-full h-screen overflow-scroll`}>
            <div className='flex justify-between px-8 py-4 items-center transition-all duration-[300ms]'>
                {!collapsed ? <img src={Logo} className='h-10 w-auto'/> : null}
                <div className='h-[32px] w-[32px] '>
                    {/* <img src={Menu} className='h-[24px] w-[24px] cursor-pointer block' onClick={()=>setCollapsed(!collapsed)}/> */}
                </div>
            </div>
            <div className='w-auto mt-8 px-4'>
                {
                    data.map((item,index)=>
                    {
                        return <SidebarMenuItem collapsed={collapsed} data={item} key={index} setActive={setActiveMenu} active={activeMenu}/>
                    })
                }
                <div className={`w-full flex px-4 items-center cursor-pointer `} onClick={()=>onLogout()}>
                <div className='w-full flex items-center'>
                    <div className='py-2'>
                    <BiLogOut color={'#000'} fontSize={20}/>
                    </div>
                    <div className='ml-4'>
                        <p className='text-[14px] tracking-[-.15px] font-medium text-[#000] '>Logout</p>
                    </div>
                </div>
        </div>
            </div>
        </div>
        <div className={`md:hidden fixed z-[99]  w-screen bg-[#0F172A] h-auto  transition-all duration-[300ms] ${collapsed ? 'w-[80px]': 'w-full'}`}>
        <div className='flex justify-between px-8 py-5 items-center transition-all duration-[300ms]'>
                {/* {!collapsed ? <img src={Logo} className='h-6 w-auto'/> : null} */}
                <div className='h-[18px] w-[18px] '>
                    {/* <img src={Menu} className='h-[18px] w-[18px] cursor-pointer block' onClick={()=>setCollapsed(!collapsed)}/> */}
                </div>
            </div>
        </div>
        {/* <div className='flex-1 mt-16 md:mt-0 h-screen overflow-hidden'> */}
        <div className='flex flex-col mt-16 md:mt-0 h-screen overflow-hidden w-full bg-white px-0'>
            <Outlet/>
        </div>
    </div>
  )
}
