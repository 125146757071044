import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import { LIMIT } from '../../helpers/constants'
import RightIcon from '../../images/right.svg'
import SortIcon from '../../images/sort.svg'
import FilterIcon from '../../images/filter.svg'
import RupeeIcon from '../../images/rupee.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import FilterOrder from './components/FilterOrder'
import Toast from 'react-toastify'
import { getDateFormat, getTimeFormat, renderWeight } from '../../helpers/utils'
import CreateOrder from './components/CreateOrder'
import { useSelector } from 'react-redux'

export default function Order() {

    const [searchText,setSearchText] = useState('')
    const [reps,setOrders] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})
    const [vieworder,setVieworder] = useState(false)
    const [sort,setSort] = useState('-createdAt')
    const [exporting,setExporting] = useState(false)

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [summary,setSummary] = useState({pendingOrders:0,totalOrders:0,cancelledOrders:0,totalAmount:0,totalQty:0})
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
    const [showFilters,setShowFilters] = useState(false)
    const [submitting,setSubmitting] = useState(false)
    const authInfo = useSelector(state=>state.auth)

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})

    async function exportOrders(){

        try 
        {
            setExporting(true)
            let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&sort=${sort}`;
            if(filters.buyerId) query+=`&buyerId=${filters.buyerId.id}`
            if(filters.status) query+=`&orderStatus=${filters.status}`
            if(filters.startDate) query+=`&startDate=${`${new Date(filters.startDate).getDate()}-${new Date(filters.startDate).getMonth()+1}-${new Date(filters.startDate).getFullYear()}`}`
            if(filters.endDate) query+=`&endDate=${`${new Date(filters.endDate).getDate()}-${new Date(filters.endDate).getMonth()+1}-${new Date(filters.endDate).getFullYear()}`}`
            let response = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders/export?${query}`,{responseType:'blob'})
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;

            // Set the filename for the downloaded file
            link.setAttribute('download', 'retailers_export.xlsx');

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Cleanup
            link.parentNode.removeChild(link);
            setExporting(false)
        } catch (error) {
            setExporting(false)
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const RightContent = ()=>
    {
        return <div>
            {/* <button type='button' disabled={exporting} className='btn-md mr-4' onClick={()=>exportOrders()}>{exporting ? 'Exporting...' : 'Export'}</button> */}
          {authInfo.role === 'admin' || authInfo.role === 'sales' ? <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Create Sales Order</button> : null}
        </div>
    }

    async function getOrders(){

        try 
        {
            console.log('filters',filters)
            let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&sort=${sort}`;
            if(filters.buyerId) query+=`&buyerId=${filters.buyerId.id}`
            if(filters.status) query+=`&status=${filters.status}`
            if(filters.startDate) query+=`&startDate=${`${new Date(filters.startDate).getDate()}-${new Date(filters.startDate).getMonth()+1}-${new Date(filters.startDate).getFullYear()}`}`
            if(filters.endDate) query+=`&endDate=${`${new Date(filters.endDate).getDate()}-${new Date(filters.endDate).getMonth()+1}-${new Date(filters.endDate).getFullYear()}`}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/order?${query}`)
            setOrders(res.data.orders)
            setCount(res.data.totalCount)
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 


    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }



    useEffect(()=>
    {
        getOrders();
        // getOrderSummary();
    },[offset,searchText,filters,sort])


    const onOrderCreate = async(e,manageData)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let orderProducts=[];
            manageData.products.map((item,index)=>
            {
                if(item && ((item.cartons && item.cartons > 0) || (item.product)) ) return orderProducts.push({productId:item.productId,cartons:item.cartons})
            })
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/order`,{...manageData,products:orderProducts})  
            getOrders()
            setShowManage(false)
            setSubmitting(false)
            InfoToast('Order Created')
        } catch (error) {
            setSubmitting(false)
            toast('Order Creating Failed')
            
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }


  return (
    <>
        <Header title={'Sales Management'} RightContent={RightContent}/>
        <div className=' flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search order'} label={'Search order'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                    </div>
                    </div>
            </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.distributorId || filters.retailerId || filters.teamId || filters.salesRepId || filters.startDate || filters.endDate) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                
                    <td><p onClick={()=>onSortPress('orderId')} className='cursor-pointer flex items-center hover:text-black'>Order ID {sort === 'orderId' || sort === '-orderId'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('buyerId')} className='cursor-pointer flex items-center hover:text-black'>Buyer {sort === 'buyerId' || sort === '-buyerId'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('createdAt')} className='cursor-pointer flex items-center hover:text-black'>Ordered On {sort === 'createdAt' || sort === '-createdAt'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('expectedDeliveryDate')} className='cursor-pointer flex items-center hover:text-black'>Delivery Date {sort === 'expectedDeliveryDate' || sort === '-expectedDeliveryDate'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    {/* <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td> */}
                    {/* <td><p>Action</p></td> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        reps.map((item,index)=>
                        {
                            return <tr onClick={()=>navigate(`/sales/${item.id}`)}>
                                <td className='capitalize w-full'>
                                    <div>
                                        <p className='text-[14px] font-regular my-0'>#{item.orderId}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-[14px] font-regular my-0'>{item.buyer?.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-[14px] font-regular my-0'>{getDateFormat(item.orderDate)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-[14px] font-regular my-0'>{item.expectedDeliveryDate ? getDateFormat(item.expectedDeliveryDate) : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[13px] font-regular px-3 inline-block py-1 rounded-md ${item.status ===  'cancelled' ? 'text-[#454545] bg-gray-200 ' : 'bg-[#EBF6EE] text-green-700' } `}>{item.status ===  'cancelled' ? 'Cancelled' : 'Created'}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* <div className=' flex justify-between items-stretch border-t border-[#e3e3e3] bg-white w-full'>
            <div className='flex px-6 py-2 justify-between w-full'>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Orders(Total/Cancelled/Pending) - {summary.totalOrders}/{summary.cancelledOrders}/{summary.pendingOrders}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Points - {summary.totalPoints}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Weight - {(summary.totalWeight/1000).toFixed(2)}Kgs</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Amount - {summary.totalAmount}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Qty - {summary.totalQty}</p>
            </div>
        </div> */}
        { showManage.status ? <CreateOrder submitting={submitting} onClose={setShowManage} edit={showManage.edit} onSubmit={onOrderCreate}/> : null}
        { showFilters ? <FilterOrder onSubmit={onFilterSubmit} id={vieworder} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}

