import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "react-select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, LIMIT, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { ErrorToast } from "../../../helpers/toasters"
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import FilterSelect from "../../../components/FilterSelect"

export default function ManageBlend({onClose,edit=false,onSubmit,updateData,submitting})
{
    const [manageData,setManageData] = useState(!edit ? {batchNumber:'',totalWeight:'',bags:'',blendMaterials:[{rawTeaId:'',teabags:[{id:'',type:'',numberOfBags:''}]}],blendLeftovers:[{blendUsedId:'',totalWeight:''}]} : {...updateData,batchNumber:updateData.batchNumber,totalWeight:updateData.totalWeight,bags:updateData.bags,blendName:'',contactNumber:updateData.contactNumber,contactPerson:updateData.contactPerson,contactEmail:updateData.contactEmail,status:updateData.status})
    const formName = 'manageGarden';
    const [gardens,setGardens]  = useState([])
    const [rawTea,setRawTea]  = useState([])
    const [blendedTea,setBlendedTea]  = useState([])
    const [warehouses,setWarehouses] = useState([])
    const [selectedWarehouse,setSelectedWarehouse] = useState(null)
    const [count,setCount] = useState(0)
    const [totalWeight,setTotalWeight] = useState({blendMaterialsTotal:0,blendLeftoversTotal:0})
    const [totalRate,setTotalRate] = useState({blendMaterialCost:0,blendLeftoverCost:0})
    const [salesOrder,setSalesOrder] = useState([])

    async function getGardens(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/garden?populate=true${query}`)
            if(res.data.data) 
            {
                setGardens(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting gardens')
        }
    }
    
    async function getRawTea(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tea?populate=true${query}`)
            setRawTea(res.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting warehouses')
        }
    }
    
    async function getBlendedTea(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend?populate=true${query}`)
            setBlendedTea(res.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting blended Tea')
        }
    }
   
    async function getSalesOrder(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/order?limit=${150}&offset=${0}&sort=-createdAt&populate=true${query}`)
            setSalesOrder(res.data.orders)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting Sales Order')
        }
    }
    
    // async function getWarehouseInfo(){

    //     try 
    //     {
    //         let query = `&limit=${LIMIT}&offset=${0}`;
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/warehouse/${manageData.warehouseId}?populate=true${query}`)
    //         setSelectedWarehouse(res.data)
    //         // if(res.data.data) 
    //         // {
    //         //     setCount(res.data.count)
    //         // }
    //     } catch (error) {
    //         console.log(error)
    //         ErrorToast('Error getting warehouses')
    //     }
    // }

    const handleBlendMaterialChange = async (index, field, value,rawTea) => {
        const updatedMaterials = manageData.blendMaterials.map((material, i) => {
            if (i === index) {
                if (field === 'rawTeaId') {
                    // Find the tea bags for this raw tea from rawTea data
                    const selectedRawTea = rawTea.find(tea => tea.id === value);
                    const teabags = selectedRawTea?.teabags || [];
                    return { 
                        ...material, 
                        rate:selectedRawTea?.rate,
                        [field]: value,
                        teabags: teabags.map(bag => ({
                            id: bag.id,
                            type: bag.type,
                            numberOfBags: '',
                            remainingBags:bag.remainingBags,
                        }))
                    };
                }
                return { ...material, [field]: value };
            }
            return material;
        });
        setManageData({ ...manageData, blendMaterials: updatedMaterials });
    };
    
    const handleBlendLeftoverChange = (index, field, value,selectedBlendTea) => {
        const updatedMaterials = manageData.blendLeftovers.map((material, i) => {
            if (i === index) {
                return { ...material, [field]: value,leftoverCost:selectedBlendTea?.blendLeftoverCost,leftoverTotalWeight:selectedBlendTea?.blendWeight };
            }
            return material;
        });
        setManageData({ ...manageData, blendLeftovers: updatedMaterials });
    };

    const handleBlendMaterialBagsChange = (materialIndex, bagIndex, value) => {
        const updatedMaterials = manageData.blendMaterials.map((material, i) => {
            if (i === materialIndex) {
                const updatedBags = material.teabags.map((bag, j) =>
                    j === bagIndex ? { ...bag, numberOfBags: value } : bag
                );
                return { ...material, teabags: updatedBags };
            }
            return material;
        });
        setManageData({ ...manageData, blendMaterials: updatedMaterials });
    };

    const getTotalSelectedWeight = ()=>{
        let blendMaterialsTotal = 0
        let blendLeftoversTotal = 0
        
        manageData.blendMaterials.forEach(item=>{
            blendMaterialsTotal += item.teabags.reduce((acc,bag)=>acc + (parseInt(bag.numberOfBags || 0) * parseInt(bag.type || 0)), 0)
        })

        manageData.blendLeftovers.forEach(item=>{
            blendLeftoversTotal += parseInt(item.totalWeight || 0)
        })

        return {
            blendMaterialsTotal,
            blendLeftoversTotal
        }
    }

    const getTotalRate = ()=>{
        let blendMaterialCost = 0
        let blendLeftoverCost = 0
        manageData.blendMaterials.forEach(item=>{
            blendMaterialCost += item.teabags.reduce((acc,bag)=>acc + (parseInt(bag.numberOfBags || 0) * parseInt(bag.type || 0) * parseInt(item.rate || 0)), 0)
        })
        manageData.blendLeftovers.forEach(item=>{
            blendLeftoverCost += parseInt(item.totalWeight || 0) * (parseInt(item.leftoverCost || 0) + (parseInt(item.blendMaterialCost/parseInt(item.leftoverTotalWeight || 0)) || 0))
        })
        setTotalRate({blendMaterialCost:blendMaterialCost,blendLeftoverCost:blendLeftoverCost})
    }

    useEffect(()=>{
        setTotalWeight(getTotalSelectedWeight())
        getTotalRate()
    },[manageData.blendMaterials,manageData.blendLeftovers])

    // Add a new blend material
    const addBlendMaterial = () => {
        setManageData({
            ...manageData,
            blendMaterials: [...manageData.blendMaterials, { rawTeaId: '', teabags: [{id:'',type:'',numberOfBags:''}] }]
        });
    };

    // Remove a blend material
    const removeBlendMaterial = (index) => {
        const updatedMaterials = manageData.blendMaterials.filter((_, i) => i !== index);
        setManageData({ ...manageData, blendMaterials: updatedMaterials });
    };

    const removeBlendMaterialBags = (materialIndex, bagIndex) => {
        const updatedMaterials = manageData.blendMaterials.map((material, i) => {
            if (i === materialIndex) {
                const updatedBags = material.teabags.filter((_, j) => j !== bagIndex);
                return { ...material, teabags: updatedBags };
            }
            return material;
        });
        setManageData({ ...manageData, blendMaterials: updatedMaterials });
    };

    const addBlendLeftover = () => {
        setManageData({
            ...manageData,
            blendLeftovers: [...manageData.blendLeftovers, { blendUsedId: '', totalWeight: '' }]
        });
    };

    const removeBlendLeftover = (index) => {
        const updatedLeftovers = manageData.blendLeftovers.filter((_, i) => i !== index);
        setManageData({ ...manageData, blendLeftovers: updatedLeftovers });
    };

    useEffect(()=>
        {
            getGardens()
            getRawTea()
            getBlendedTea()
            getSalesOrder()
        },[])
   
        // useEffect(()=>
        // {
        //     getWarehouseInfo()
        // },[manageData.warehouseId])




    return <><Popup size="sm" title={edit ? 'Update Blend Entry' : 'Create Blend Entry'} submitting={submitting} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full">
            <div className="col-span-2">
                <label>Batch Number</label>
                <Input type='text' placeholder={'Enter Batch Number'} required={true}  value={manageData.batchNumber} setValue={(value)=>setManageData(data=>({...data,batchNumber:value}))}/>
            </div>
             <div className="col-span-2 z-50 relative">
                <label>Sales Order ID</label>
                <Select isClearable={true} isSearchable={true} defaultValue={manageData.salesOrderId} options={salesOrder} placeholder={'Select Sales Order'} classNamePrefix={'filter-select'} classNames={{valueContainer:()=>'text-xs',container:()=>'bg-white focus:outline-0 px-0 h-[100%] py-0 border-0 outline-0',indicatorSeparator:()=>'hidden',control:()=>'border-0 focus:ring-0 active:ring-0 focus:ring-offset-0',menu:()=>'rounded-[2px] border-1 border-gray-100'}} getOptionLabel={option=>`${option.orderId} - ${option.buyer?.name}`} getOptionValue={option=>option.id} className={`filter-select`} onChange={(value)=>setManageData(data=>({...data,salesOrderId:value}))} required={true}>
                </Select>
            </div>
            <div className="col-span-2">
                <label>Blend Name</label>
                <Input type='text' placeholder={'Enter Blend Name'} required={true}  value={manageData.blendName} setValue={(value)=>setManageData(data=>({...data,blendName:value}))}/>
            </div>

            <div className="col-span-4 mt-4">
                <div className="flex justify-between items-center">
                    <p className="text-sm font-medium mb-4">Blend Materials</p>
                    <div className="ml-2 top-1.5 right-0 z-10"><button type="button" onClick={()=>addBlendMaterial()}><IoAddCircle color="black" fontSize={24}/></button></div>
                </div>
                {
                    manageData.blendMaterials.map((item,index)=>
                    {
                        return <BlendMaterial onTeaBagsChange={handleBlendMaterialBagsChange} onChange={handleBlendMaterialChange} onRemove={removeBlendMaterial} onBagRemove={removeBlendMaterialBags} data={item} manageData={manageData} setManageData={setManageData} key={index} index={index}/>
                    })
                }
            </div>
            
            <div className="col-span-4 mt-4">
                <div className="flex justify-between items-center">
                    <p className="text-sm font-medium mb-4">Blend Leftovers</p>
                    <div className="ml-2 top-1.5 right-0 z-10"><button type="button" onClick={()=>addBlendLeftover()}><IoAddCircle color="black" fontSize={24}/></button></div>
                </div>
                {
                    manageData.blendLeftovers.map((item,index)=>
                    {
                        return <BlendLeftOverMaterial onChange={handleBlendLeftoverChange} onRemove={removeBlendLeftover}  data={item} manageData={manageData} setManageData={setManageData} key={index} index={index}/>
                    })
                }
            </div>
            </div>
            <div className="col-span-4 flex justify-between items-start">
                <div> 
                    <label>Total Weight</label>
                    <p className="text-sm font-medium mb-4">{totalWeight.blendMaterialsTotal + totalWeight.blendLeftoversTotal}Kg ({totalWeight.blendMaterialsTotal}Kg + {totalWeight.blendLeftoversTotal}Kg)</p>
                </div>
                <div> 
                    <label className="text-right">Raw Tea Cost</label>
                    <p className="text-sm font-medium mb-4 text-right">Rs. {totalRate.blendMaterialCost + totalRate.blendLeftoverCost} ({totalRate.blendMaterialCost}+{totalRate.blendLeftoverCost})</p>
                </div>
            </div>
            </div>
        </form>
    </Popup>
    </>
}


const BlendMaterial = ({data,manageData,setManageData,onChange,onRemove,index,onTeaBagsChange,onBagRemove})=>
{
    const [rawTea,setRawTea]  = useState([])

    async function getRawTea(){

        try 
        {
            let query = `&limit=${50}&offset=${0}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/tea?populate=true${query}`)
            setRawTea(res.data.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting warehouses')
        }
    }
    useEffect(()=>
    {
        getRawTea()
    },[])
    return <div className="w-full grid grid-cols-12 relative  mb-4 bg-[#f5f5f5] p-2">
        <div className="col-span-12 w-full rounded-md">
                <select className="border border-gray-200" placeholder={'Select Raw Tea'} customLabel={'name'} customValue={'id'}  value={manageData.blendMaterials[index].rawTeaId} onChange={(e) => onChange(index, 'rawTeaId', e.target.value,rawTea)}>
                    <option value={''}>Select Raw Tea</option>
                {
                    rawTea.map((item,index)=>
                    {
                        return <option value={item.id} key={index}>#{item.invoiceNumber}- {item.garden.name}</option>
                    })
                }
                </select>

                <div className="w-full">
                    <div className="w-full">
                        {
                            manageData.blendMaterials[index].teabags?.map((item,i)=>
                            {
                                return <BlendMaterialBags materialIndex={index} rawTeaOutside={rawTea} data={item} manageData={manageData} setManageData={setManageData} onChange={onTeaBagsChange} onRemove={onRemove} onBagRemove={onBagRemove} index={i} key={i}/>
                            })
                        }
                    </div>
                </div>
        </div>

        {index > 0 ? <div className="ml-2 absolute top-1.5 right-0 z-10"><button type="button" onClick={()=>onRemove(index)}><IoRemoveCircle color="red" fontSize={24}/></button></div>  : null}
    </div>
}
const BlendLeftOverMaterial = ({data,manageData,setManageData,onChange,onRemove,index,onTeaBagsChange})=>
{
    const [rawTea,setRawTea]  = useState([])
    const [remainingWeight,setRemainingWeight] = useState(0)
    const [selectedBlendTea,setSelectedBlendTea] = useState(null)

    async function getBlendTea(){

        try 
        {
            let query = `&limit=${50}&offset=${0}&status=4&minWeight=true`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/blend?populate=true${query}`)
            setRawTea(res.data.data)
        } catch (error) {
            console.log(error)
            ErrorToast('Error getting warehouses')
        }
    }

    function getRemainingWeight(id){
        let blend = rawTea.find(item=>item.id === id)
        setSelectedBlendTea(blend)
        setRemainingWeight(blend?.remainingWeight ? blend.remainingWeight : 0)
    }
    
    useEffect(()=>{
        getRemainingWeight(manageData.blendLeftovers[index].blendUsedId)
    },[manageData.blendLeftovers[index].blendUsedId])

    useEffect(()=>
    {
        getBlendTea()
    },[manageData.blendLeftovers[index].blendUsedId])
    return <div className="w-full flex grid-cols-12 relative  mb-4 border border-gray-200 rounded-md overflow-hidden">
        <div className="col-span-4 w-full rounded-md">
                <select className="border-0 border-r border-r-gray-200 rounded-none text-xs" placeholder={'Select Raw Tea'} customLabel={'name'} customValue={'id'}  value={manageData.blendLeftovers[index].blendUsedId} onChange={(e) => onChange(index, 'blendUsedId', e.target.value,selectedBlendTea)}>
                    <option value={''}>Select Blend Tea</option>
                {
                    rawTea.map((item,index)=>
                    {
                        return <option value={item.id} key={index}>#{item.batchNumber}- {item.blendName}</option>
                    })
                }
                </select>
        </div>
        <div className="col-span-4 border-r border-r-gray-200">
                    <input type='number' className="text-xs font-medium h-full w-full px-2 py-2" placeholder={'Remaining Bags'} value={remainingWeight} disabled/>
            </div>
            <div className="col-span-4">
                    <input type='number' max={remainingWeight} className="text-xs font-medium h-full w-full px-2 py-2" placeholder={'Weight'} value={manageData.blendLeftovers[index].totalWeight} onChange={(e) => onChange(index, 'totalWeight', e.target.value,selectedBlendTea)}/>
            </div>

        {index > 0 ? <div className="ml-2 absolute top-1.5 right-0 z-10"><button type="button" onClick={()=>onRemove(index)}><IoRemoveCircle color="red" fontSize={24}/></button></div>  : null}
    </div>
}

const BlendMaterialBags = ({data,manageData,setManageData,onChange,onRemove,index,rawTeaOutside,materialIndex,onBagRemove})=>
    {
        const [rawTea,setRawTea]  = useState([])
    

        return <div className="grid grid-cols-12 relative border border-gray-200 rounded-md my-4 overflow-hidden ">
            <div className="col-span-4 border-r border-r-gray-200 flex items-center">
                    {/* <input type='number' className="text-xs font-medium h-full w-full px-2 py-2" placeholder={'Weight'} value={manageData.blendMaterials[materialIndex].teabags[index].type} onChange={(e) => onChange(index, 'type', e.target.value)}/> */}
                    <p className="text-xs text-black bg-white h-full w-full px-2 py-3">{data.type} Kg</p>
            </div>
            <div className="col-span-4 border-r border-r-gray-200">
                    <input type='number' className="text-xs font-medium h-full w-full px-2 py-2" placeholder={'Remaining Bags'} value={manageData.blendMaterials[materialIndex].teabags[index].remainingBags} disabled/>
            </div>
            <div className="col-span-4">
                    <input type='number' className="text-xs font-medium h-full w-full px-2 py-2" placeholder={'No of Bags'} value={manageData.blendMaterials[materialIndex].teabags[index].numberOfBags} onChange={(e) => onChange(materialIndex,index, e.target.value)}/>
            </div>
    
            <div className="ml-2 absolute top-1.5 right-0 z-10"><button type="button" onClick={()=>onBagRemove(materialIndex,index)}><IoRemoveCircle color="red" fontSize={24}/></button></div>
        </div>
    }